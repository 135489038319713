
import { Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import requests from "api/requests";
import { addDays, addMinutes, format, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import TableItems from "./tableItems";
import { ICAOrder } from "./types";

export default function CAOrders(){
  const now = startOfDay(new Date());

  const [end, setEnd] = useState<Date|null>(addMinutes(now, -1));
  const [start, setStart] = useState<Date|null>(addDays(now, -7));
  const [items, setItems] = useState<ICAOrder[]|null>(null)

  useEffect(()=>{
    if(!start || !end){
      setItems([]);
    }else{
      requests.get("/finance/ca-orders/", {
        start: format(start, "yyyy-MM-dd HH:mm:00"),
        end: format(end, "yyyy-MM-dd HH:mm:59"),
      }).then((r)=>{
        setItems(r.body)
      })
    }
  }, [start, end])

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Початок"
            value={start}
            onChange={(newValue) => {
              setStart(newValue);
            }}
          />
        </Grid>
        <Grid item>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Кінець"
            value={end}
            onChange={(newValue) => {
              setEnd(newValue);
            }}
          />
        </Grid>
      </Grid>
      
      {items!==null && (
        <TableItems
          items={items}
        />
      )}
    </div>
  )
}