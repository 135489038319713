import { Box, Rating, Typography } from "@mui/material";
import requests from "api/requests";
import Rank from "components/driver/rank";
import { IDriverRating } from "components/driverRating/types";
import { addDays, format } from "date-fns";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useRouteMatch } from "react-router-dom";

interface IRatingItem{
  id: number;
  name: string;
}

export default function RatingDriverPage(){
  const match:any = useRouteMatch();
  
  const [ratingItems, setRatingItems] = useState<IRatingItem[]>([]);
  const [rating, setRating] = useState<IDriverRating|null>(null);

  useEffect(()=>{
    requests.get('/admin/webcab/rating/item/').then((r) => {
      setRatingItems(r.body)
    })
  }, [])

  useEffect(()=>{
    requests.get(`/admin/webcab/rating/driver/${match.params.id}/`, {
      start: format(addDays(new Date(), -3 * 30), "yyyy-MM-dd")
    }).then((r) => {
      let _items:IDriverRating = r.body
      let driver_items:any = {};
      r.body.items.forEach((element:any) => {
        driver_items[element.item] = element.count
      });
      setRating({
        ..._items,
        items: driver_items
      })
    })
  }, [match.params.id])

  if(rating === null){
    return null;
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 360 }}>
      <Box
        sx={{mb: 5}}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Rating
            name="read-only"
            value={rating.rating.average}
            readOnly
            size='small'
            precision={0.5}
            sx={{
              verticalAlign: 'middle'
            }}
          />
          &nbsp;(<NumberFormat value={rating.rating.average} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />)  
        </Box>
        <Box>
          <Rank value={rating.rank}/>
        </Box>
      </Box>
      
      {ratingItems.map((i)=>(
        <Box
          key={i.id}
          sx={{
            py: 1
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>{i.name}</Box>
          <Box>{rating.items[i.id] || (<Typography color="textSecondary">0</Typography>)}</Box>
        </Box>
      ))}
      
    </Box>
  )
}